<template>
    <main>
        <div class="titlebar titlebar-sm scheme-dark bg-dark-blue">

            <div class="titlebar-inner py-5">
                <div class="container titlebar-container">
                    <div class="row titlebar-container">

                        <div class="titlebar-col col-md-6">
                            <h1 class="font-size-46 mb-1 text-white">Who we are ?</h1>
                            <p class="font-size-14 text-fade-white-08">Basically, we design and build amazing things</p>
                        </div><!-- /.titlebar-col -->

                        <div class="titlebar-col col-md-6 text-md-right">
                            <ol class="breadcrumb reset-ul inline-nav">
                                <li><a href="/"><span>Home</span></a></li>
                                <li><span>About</span></li>
                            </ol>
                        </div><!-- /.titlebar-col -->

                    </div><!-- /.titlebar-row -->
                </div><!-- /.titlebar-container -->
            </div><!-- /.titlebar-inner -->

        </div>
        <section class="vc_row pt-120 pb-80">
            <div class="container">
                <div class="row">

                    <div class="lqd-column col-md-6">

                        <h6 class="font-size-12 text-uppercase ltr-sp-2 text-purple">About VisionTechers</h6>

                        <hr class="w-10 ml-0 mr-0">

                        <p class="font-size-30 lh-16 font-weight-light text-black">We provide enterprises with innovative technology for small to space enterprises
                        </p>

                    </div><!-- /.col-md-6 -->

                    <div class="lqd-column col-md-5 col-md-offset-1">

                        <div class="lqd-parallax-images-4 sm-pull-up text-md-right">

                            <div class="liquid-counter liquid-counter-default liquid-counter-lg2 mb-0">
                                <p class="font-size-30 mb-0 text-purple">Since</p>
                                <div class="liquid-counter-element font-weight-normal font-size-160 text-purple" data-enable-counter="true" data-counter-options='{"targetNumber":"2018","blurEffect":true}'>
                                    <span>2018</span>
                                </div><!-- /.liquid-counter-element -->
                            </div><!-- /.liquid-counter -->

                            <div class="liquid-img-group-container">
                                <div class="liquid-img-group-inner">
                                    <div class="liquid-img-group-single" data-reveal="true" data-reveal-options='{ "direction":"tb", "bgcolor":"rgb(41, 45, 53)" }'>
                                        <div class="liquid-img-group-img-container">
                                            <div class="liquid-img-container-inner box-shadow-3" data-parallax="true" data-parallax-from='{"translateY":22}' data-parallax-to='{"translateY":23}' data-parallax-options='{"overflowHidden":false,"easing":"linear"}'>
                                                <figure>
                                                    <img src="/assets/img/actual/talk2.jpg" alt="About Visiontechers" />
                                                </figure>
                                            </div><!-- /.liquid-img-container-inner -->
                                        </div><!-- /.liquid-img-group-img-container -->
                                    </div><!-- /.liquid-img-group-single -->
                                </div><!-- /.liquid-img-group-inner -->
                            </div><!-- /.liquid-img-group-container -->

                            <div class="liquid-img-group-container">
                                <div class="liquid-img-group-inner">
                                    <div class="liquid-img-group-single" data-reveal="true" data-reveal-options='{"direction":"rl", "bgcolor":"rgb(41, 45, 53)"}'>
                                        <div class="liquid-img-group-img-container">
                                            <div class="liquid-img-container-inner box-shadow-3" data-parallax="true" data-parallax-from='{"translateY":46}' data-parallax-to='{"translateY":-81}' data-parallax-options='{"overflowHidden":false,"easing":"linear"}'>
                                                <figure>
                                                    <img src="https://images.unsplash.com/photo-1528901166007-3784c7dd3653?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80" alt="About Visiontechers" />
                                                </figure>
                                            </div><!-- /.liquid-img-container-inner -->
                                        </div><!-- /.liquid-img-group-img-container -->
                                    </div><!-- /.liquid-img-group-single -->
                                </div><!-- /.liquid-img-group-inner -->
                            </div><!-- /.liquid-img-group-container -->

                        </div><!-- /.lqd-parallax-images-4 -->

                    </div><!-- /.col-md-5 -->

                </div><!-- /.row -->
            </div><!-- /.container -->
        </section>

        <section class="vc_row pt-135 pb-135 bg-cover" style="background-image: url(https://images.unsplash.com/photo-1545935950-b7a28791ad7a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80)" data-parallax="true" data-parallax-options='{"parallaxBG": true}'>

            <div class="liquid-row-overlay" style="background:rgba(9, 17, 35, 0.645)"></div>

            <div class="container">
                <div class="row">

                    <div class="lqd-column col-md-8 col-md-offset-2 px-md-7 text-center">

                        <p class="h3 font-weight-bold text-white" data-fittext="true" data-fittext-options='{"compressor": 0.75, "maxFontSize": 40, "minFontSize": "currentFontSize"}'>
                            Our vision is to build realities out of ideas while solving <span class="text-purple-20">tomorrow's problems with today's solutions.</span>
                        </p>

                    </div><!-- /.col-md-8 col-md-offset-2 -->

                </div><!-- /.row -->
            </div><!-- /.container -->

        </section>

        <section class="vc_row pt-100 pb-100 bg-gray">
            <div class="container">
                <div class="row d-flex flex-wrap align-items-center">

                    <div class="lqd-column col-md-6 col-xs-12 mb-5 mb-md-0">

                        <div class="carousel-container carousel-nav-floated carousel-nav-center carousel-nav-middle carousel-nav-md carousel-nav-square carousel-nav-solid carousel-dots-style4">
                            <div class="carousel-items row mx-0">

                                <div class="carousel-item col-xs-12 px-0 mb-4">
                                    <figure>
                                        <img src="/assets/img/actual/stock2.jpg" alt="Carousel Item">
                                    </figure>
                                </div><!-- /.carousel-item -->

                            </div><!-- /.carousel-items -->
                        </div><!-- /.carousel-container -->

                    </div><!-- /.lqd-column col-md-6 -->

                    <div class="lqd-column col-md-6 pl-md-6">

                        <h2 class="h3 mt-0">Why choose us over other companies?</h2>
                        <p class="lh-2 font-size-16">We are the 'A' team of talents who make perfect use of their possibilities. Our wide range of tech and business advisory skills makes us well placed to proffer cutting edge solutions to challenges. Whether it's providing digital footprint for your business, social media marketing, providing customer support for your business while you focus on production and sales or even handling your sales, We are well equipped to take your business to the next level of profitability.</p>

                    </div><!-- /.col-md-6 -->

                </div><!-- /.row -->
            </div><!-- /.container -->
        </section>

        <!--<section class="vc_row pt-75 pb-75">
            <div class="container">
                <div class="row">

                    <div class="lqd-column col-md-6">

                        <div class="testimonial testimonial-whole-filled testimonial-whole-shadowed text-left">

                            <div class="testimonial-quote">
                                <blockquote>
                                    <p class="font-size-18 lh-2">“Not in a million years did I expect you to read my mind, but you did. Thank you for everything – you have really created a fabulous image for our company.”</p>
                                </blockquote>
                            </div>&lt;!&ndash; /.testimonial-qoute &ndash;&gt;

                            <div class="testimonial-details">
                                <div class="testimonial-info">
                                    <h5>Caleb Cruz</h5>
                                    <h6 class="font-weight-normal">Apple marketing manager</h6>
                                </div>&lt;!&ndash; /.testimonial-info &ndash;&gt;

                            </div>&lt;!&ndash; /.testimonial-details &ndash;&gt;

                        </div>&lt;!&ndash; /.testimonial &ndash;&gt;

                    </div>&lt;!&ndash; /.lqd-column col-md-6 &ndash;&gt;

                    <div class="lqd-column col-md-6">

                        <div class="testimonial testimonial-whole-filled testimonial-whole-shadowed text-left">

                            <div class="testimonial-quote">
                                <blockquote>
                                    <p class="font-size-18 lh-2">“Not in a million years did I expect you to read my mind, but you did. Thank you for everything – you have really created a fabulous image for our company.”</p>
                                </blockquote>
                            </div>&lt;!&ndash; /.testimonial-qoute &ndash;&gt;

                            <div class="testimonial-details">
                                <div class="testimonial-info">
                                    <h5>Caleb Cruz</h5>
                                    <h6 class="font-weight-normal">Apple marketing manager</h6>
                                </div>&lt;!&ndash; /.testimonial-info &ndash;&gt;

                            </div>&lt;!&ndash; /.testimonial-details &ndash;&gt;

                        </div>&lt;!&ndash; /.testimonial &ndash;&gt;

                    </div>&lt;!&ndash; /.lqd-column col-md-6 &ndash;&gt;

                </div>&lt;!&ndash; /.row &ndash;&gt;
            </div>&lt;!&ndash; /.container &ndash;&gt;
        </section>-->

        <section class="vc_row pt-50 pb-200">
            <div class="container">
                <div class="row">

                    <div class="lqd-column col-md-10 col-md-offset-1 text-center" data-custom-animations="true"
                         data-ca-options='{"triggerHandler":"inview","animationTarget":"all-childs","duration":1200,"delay":100,"initValues":{"translateY":80,"opacity":0},"animations":{"translateY":0,"opacity":1}}'>

                        <header class="fancy-title fancy-title-big mb-6">
                            <h2 data-split-text="true" data-split-options='{"type":"lines"}'>
                                Let's create something
                                <span class="font-size-2-15x lh-15 text-purple" data-fittext="true" data-fittext-options='{ "maxFontSize": 70, "minFontSize": 40, "compressor": 0.5 }'>extra-ordinary</span>
                                for you too?
                            </h2>
                        </header><!-- /.fancy-title -->

                        <a href="/contact-us"
                           class="btn btn-purple text-uppercase circle btn-bordered border-thin font-size-14 font-weight-semibold"
                           data-localscroll="true" data-localscroll-options='{"scrollBelowSection":true}'>
                            <span>
                              <span class="btn-txt">Start a project</span>
                            </span>
                        </a>

                    </div><!-- /.col-md-10 col-md-offset-1 -->

                </div><!-- /.row -->
            </div><!-- /.container -->
        </section>
    </main>
</template>
<script>
    import { DynamicAssets } from "../../utils/DynamicAssets";

    export default {
        name: 'Home',
        mounted() {

            DynamicAssets.removeScriptsPage();
            DynamicAssets.loadScriptPage()
        }
    }
</script>
